/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react';
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import { useRouter } from '../../../../components';
import { handleElementLink } from '../../../../components';
import env from '../../../../env';
import { renderHtml } from './renderHtml';
import style from './htmlStyle';

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  //const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
  const bgColor = cmsUtils.payload(item, 'BgColor');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const vertical = cmsUtils.payload(item, 'Vertical') || "middle"; //top, middle, bottom
  const horizontal = cmsUtils.payload(item, 'Horizontal') || "centre"; // left, centre, right


  console.log("item ---- ", item);

  const year = (new Date()).getFullYear();
  let content = cmsOption.isCmsEdit ?
    cmsUtils.payload(item, 'Content') :
    cmsUtils.payload(item, 'Content')
      .replace(/__year__/g, year)
      .replace("__version__", "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>");

  const cssClassContainer = utils.classNames('html__container', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'htmlPanel', vertical, horizontal, cmsOption.isCmsEdit && 'html');

  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  function handleClick(e) {

    let target = "";
    if (e.target.closest('a')) {
      target = e.target.closest('a').getAttribute('target');
    }
    const processed = (target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target));
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url)
    } else {

      if (e.target.closest('a')) {
        let href = e.target.closest('a').getAttribute('href') + "";
        if (href.indexOf('#') !== -1) {
          let _href = href.split('#');
          console.log("_href", _href);
          let el = document.querySelector('section[data-cms-item-anchor="' + _href[1] + '"]');
          if (el) {
            let anchorStatus = el.getAttribute('data-anchor-status');
            if (anchorStatus === "collapse") {
              el.setAttribute('data-anchor-status', 'expand');
              el.classList.add("expand");
            }
            else {
              el.setAttribute('data-anchor-status', 'collapse');
              el.classList.remove("expand");
            }
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }

    }
  }

  const htmlBlock = useRef(null);
  return (
    <div css={style.container} className={cssClassContainer} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
    }}>
      <div style={{ position: 'relative', height: '100%' }}>
        <div ref={htmlBlock} css={style.html__item} className={cssClass} data-cms-html="true"
          data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
          onClick={(e) => handleClick(e)}>
          {renderHtml(content, cmsOption)}
        </div>
      </div>
    </div>
  )
}