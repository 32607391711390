const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#4b555a',
  colorCream : '#f1ece7',
  colorRed : '#e65f4b',
  colorNavyBlue : '#2d2d3c'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"PenneshawTitling",serif',
  familyHeader2: '"DamienText",serif',

  primaryColor: colors.colorBlue,
  fontColor: colors.colorNavyBlue,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;