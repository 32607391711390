//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    
    section : mq({
        //paddingLeft: ['15px',null],
        //paddingRight: ['15px',null,'67px']
    }),
    
    pageTiles : css(mq({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         //margin: '2rem -10px',
         //marginLeft: [`calc( (-100 / 40) * (15px + (40 - 15) * ((100vw - 768px) / (1920 - 768))))`],
         //marginRight: [`calc( (-100 / 40) * (15px + (40 - 15) * ((100vw - 768px) / (1920 - 768))))`],
         //marginLeft: [`calc(-1px * (100vw - 100%))`],
         //marginRight: [`calc(-1px * (100vw - 100%))`],
         overflow:'hidden',
         maxWidth : '1920px',
         paddingLeft : ['15px',null,'67px'],
         paddingRight : ['15px',null,'67px'],
         marginLeft: 'auto',
         marginRight: 'auto',
         '@media (min-width: 2560px)' : {
            //backgroundColor : 'red'
            //marginLeft : '-15rem',
            //marginRight : '-15rem'
         }
    })),
    pageTile__tile : css(mq({
         flex: ['1 0 100%',null,'1 0 33.3%'],
         maxWidth: ['100%%',null,'33.3%'],
         padding: ['10px',null,'20px'],
        //  '&:nth-of-type(even)' : {
        //        '.pageTile__bsyc__bg' : mq({
        //             order: ['0',null,'2'],
        //        }),
        //        '.pageTile__bsyc__content__details' : mq({
        //             order: '1'
        //        })
        //  }
    })),

    tile__content : css({
     //     display: 'flex',
     //     flexDirection: 'row',
     //     justifyContent: 'space-between',
     //     width: '100%',
     //     height: '100%',
     //     cursor: 'pointer',
     //     position: 'relative',
     //     flexWrap: 'wrap',
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details' : {
               //color: 'white',
               //backgroundColor: 'rgba(170, 150, 119,1)'
               // '&.default' : css({
               //      backgroundColor: 'rgba(0,113,188,1)'
               // }),
               // '&.red' : css({
               //      backgroundColor: 'rgba(211,61,2,1)'
               // }),
               // '&.green' : css({
               //      backgroundColor: 'rgba(0, 146, 69,1)'
               // })
               '.pageTile__bsyc__view' : {
                    backgroundColor : variables.colorRed
                }
            },
            '& .pageTile__bsyc__bg2' : {
               //transform: scale(1.05)
               transform : 'scale(1.05)'
            }
         }
    }),

    tile__flip__wrapper : css({
          //zIndex:'-1', 
          //display: 'flex',
          //flexDirection: 'row',
          //justifyContent: 'space-between',
          width: '100%',
          //height: '100%',
          cursor: 'pointer',
          position: 'relative',
          //flexWrap: 'wrap'
    }),

//     tile__bg : css(mq({
//          height: '0',
//          //paddingTop: '30%',
//          paddingTop: ['50%','35%','30%'],
//          backgroundPosition: '50%',
//          backgroundRepeat: 'no-repeat',
//          backgroundSize: 'cover',
//          flex: '1 1 300px'
//     })),

tile__bg : css(mq({
     height: '0',
     //paddingTop: ['50%','35%','30%'],
     paddingTop: ['90%',null,'95%'],
     //height: ['50%','35%','30%'],
     //flex: '1 1 300px',
     flex: '1 0 100%',
     maxWidth : '100%',
     overflow:'hidden',
     position:'relative'
})),

tile__bg2 : css(mq({
    height: '100%',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //overflow:'hidden',
    transition:'all .2s',
    position:'absolute',
    top:'0px',
    width:'100%'
})),

    tile__details : css({
         overflow: 'hidden',
         flexGrow: '1',
         //textAlign: 'center',
         width: '100%',
         bottom: '0',
         backgroundColor: 'transparent',
     //     '&.default' : css({
     //           backgroundColor: 'rgba(0, 113, 188,.5)'
     //     }),
     //     '&.red' : css({
     //           backgroundColor: 'rgba(211, 61, 2,.5)'
     //      }),
     //      '&.green' : css({
     //           backgroundColor: 'rgba(0, 146, 69,.5)'
     //      }),
         //backgroundColor : '#77cf22',
         //flex: '1 1 300px',
         flex: '1 0 100%',
         maxWidth : '100%',
         transition: 'all .2s ease-in-out',
         position:'relative',
         '& .pageTile__bsyc__container' : mq({
            //position: 'absolute',
            //position: ['static,',null,'absolute'],
            position: ['static,'],
            //top: '50%',
            //left:'50%',
            //transform: ['none',null,'translate(-50%, -50%)'],
            width:['100%',null,'100%'],
            paddingTop : '1rem',
            paddingBottom : '1rem',
            paddingLeft : '0px',
            paddingRight : '0px',
            '& .pageTile__bsyc__title' : mq({
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: ['20px',null,'30px'],
                fontFamily: variables.familyHeader,
                //textTransform: 'uppercase',
                //fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:variables.fontColor
            }),
            '& .pageTile__bsyc__desc' : {
                lineHeight: '1.3',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color:variables.fontColor
             },
             '& .pageTile__bsyc__view' : {
                display : 'inline-block',
                padding : '0.6rem 1.5rem',
                backgroundColor : variables.colorNavyBlue,
                marginTop: "1rem",
                fontSize : '0.6rem',
                color : variables.colorCream
             }
         })
    })
}