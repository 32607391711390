import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  
  top__bar : mq({
    //height: 30,
    width : '100%',
    //position: 'fixed',
    backgroundColor: variables.colorCream,
    zIndex: 2,
    display: ['none',null,null,'flex'],
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent : 'center',
    alignItems : 'center',
    '& > div' : {}
  }),

  top__bar__item : mq({
    fontSize: ['11px',null,null,null,'13px'],
    fontFamily: variables.familyHeader,
    lineHeight: 1,
    //paddingLeft: '0.5rem',
    //paddingRight: '0.5rem',
    //letterSpacing: '3px',
    //borderLeft: '1px solid red',
    '&.bar' : {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      textAlign: 'center'
    },
    'a' : {
      color: variables.colorBlue,
      '&:hover' : {
        color: variables.colorRed,
      }
    },
    'a.btn.btn-primary' : mq({
      fontSize : ['11px',null,null,null,'13px'],
      borderRadius: 0,
      paddingTop: '12px',
      paddingBottom: '13px',
      lineHeight: 1,
      backgroundColor : "#2D2D3C",
      borderColor: "#2D2D3C",
      color : 'white',
      '&:hover' : {
        backgroundColor : "#2D2D3C",
        borderColor: "#2D2D3C",
      }
    })
  }),

  top__bar__scrolled : {

  },
  
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: variables.colorCream,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    //top : ['0px',null,null,'30px']
    top : ['0px',null,null,'0px']
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
    display: ['black',null,null,'none'],
  })),
  logoImg: css(mq({
    display: 'block',
    height: [22, null, 36],
  })),
  logoScrolled: css(mq({

  })),
}