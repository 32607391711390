/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import style from './multiColumnPanel2Style';
import { renderHtml } from './renderHtml';
import Slider from "react-slick";
import { handleElementLink, useRouter, SiteLink } from '../../../../components';

export function MultiColumnPanel(props) {
    const item = props.item;
    
    const cmsOption = props.cmsOption || {};
    const bgColor = cmsUtils.payload(item, 'BgColor');
    const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
    const cssClass = utils.classNames('cms_item', 'contentPanel', 'multiColumnPanelv2', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');

    const leftPanel = cmsUtils.payload(item, 'LeftPanel') || "text"; //text, banner
    const leftPanelBanner = cmsUtils.payload(item, 'LeftPanelBanner') || "";
    const leftTextVertical = cmsUtils.payload(item, 'LeftTextVertical') || "middle"; //top, middle, bottom
    const leftTextHorizontal = cmsUtils.payload(item, 'LeftTextHorizontal') || "centre"; // left, centre, right
    const leftTextBgColor = cmsUtils.payload(item, 'LeftTextBgColor') || "transparent";
    const leftTextWidth = cmsUtils.payload(item, 'LeftTextWidth') || "middle"; //short, middle, wild

    const rightPanel = cmsUtils.payload(item, 'RightPanel') || "text";
    const rightPanelBanner = cmsUtils.payload(item, 'RightPanelBanner') || "";
    const rightTextVertical = cmsUtils.payload(item, 'RightTextVertical') || "middle"; //top, middle, bottom
    const rightTextHorizontal = cmsUtils.payload(item, 'RightTextHorizontal') || "centre"; // left, centre, right
    const rightTextBgColor = cmsUtils.payload(item, 'RightTextBgColor') || "transparent"; // left, centre, right
    const rightTextWidth = cmsUtils.payload(item, 'RightTextWidth') || "middle"; //short, middle, wild

    const linkUrl = cmsUtils.payload(item, 'LinkUrl') || "";

    //const mobileReverse = cmsUtils.payload(item, 'MobileReverse', 'no');
    //const subItems = (mobileReverse === "yes" && utils.css.screenWidth() <= 550) ? item.items.reverse() || [] : item.items || [];
    //const columnWidths = cmsUtils.payload(item, 'ColumnWidths').split(",");
    //const mobileColumnWidths = cmsUtils.payload(item, 'MobileColumnWidths').split(",");
    //const countItem = { countD: 0, countM: 0, col: "" };

    let content = cmsUtils.payload(item, 'Content') || "Please add text";
    let content2 = cmsUtils.payload(item, 'Content2') || "Please add text";

    let bgUrl = null;
    if (imageUrl)
        bgUrl = utils.site.resourcePath(imageUrl);
    else if (backgroundTile)
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

    let idFirstUse = 1;
    if (leftPanel !== "text") {
        idFirstUse = 2
    }

    return (
        <section className={cssClass} style={{
            backgroundColor: `${bgColor}`,
            backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
        }} id={item.anchorName}>
            <div className="contentPanel__bg"></div>
            <div className="container" css={style.container}>
                <div className="row">
                    <div className={`col-12 col-md-6 col__item ${(leftPanel === 'text' && rightPanel === 'banner') ? "change__flex__order" : "no__change__flex__order"}`}>
                        <GetItem
                            itemName={leftPanel}
                            content={content}
                            cmsOption={cmsOption}
                            itemId={item.itemId}
                            bannerItems={leftPanelBanner}
                            index="01"
                            checkOtherPanel={rightPanel}
                            textVertical={leftTextVertical}
                            textHorizontal={leftTextHorizontal}
                            textBgColor={leftTextBgColor}
                            textWidth={leftTextWidth}
                            idFirstUse={idFirstUse === 1 ? true : false}
                            linkUrl={linkUrl} />
                    </div>
                    <div className={`col-12 col-md-6 col__item`}>
                        <GetItem
                            itemName={rightPanel}
                            content={content2}
                            cmsOption={cmsOption}
                            itemId={item.itemId}
                            bannerItems={rightPanelBanner}
                            index="02"
                            checkOtherPanel={leftPanel}
                            textVertical={rightTextVertical}
                            textHorizontal={rightTextHorizontal}
                            textBgColor={rightTextBgColor}
                            textWidth={rightTextWidth}
                            idFirstUse={idFirstUse === 2 ? true : false}
                            linkUrl={linkUrl} />
                    </div>
                </div>
            </div>
        </section>
    )
}

function GetItem({ itemName,
    index,
    content,
    cmsOption,
    itemId,
    bannerItems,
    checkOtherPanel,
    textVertical,
    textHorizontal,
    idFirstUse,
    textBgColor,
    textWidth,
    linkUrl }) {

    const { history } = useRouter();
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const _imgs = (bannerItems === "" ? "logo.png" : bannerItems).split("|");
    //https://jaba.com.au/sites/newjaba/media/banners/ocean1_269430438.jpg

    function handleClick(e) {

        let target = "";
        if (e.target.closest('a')) {
            target = e.target.closest('a').getAttribute('target');
        }
        const processed = (target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target));
        //const link = utils.url.getLinkFromElement(e.target);
        //console.log('clicked', e, e.target, processed);

        if (processed.handled) {
            e.preventDefault();
            e.stopPropagation();
        } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
            e.preventDefault();
            e.stopPropagation();
            history.push(processed.linkInfo.url)
        } else {

            if (e.target.closest('a')) {
                let href = e.target.closest('a').getAttribute('href') + "";
                if (href.indexOf('#') !== -1) {
                    let _href = href.split('#');
                    console.log("_href", _href);
                    let el = document.querySelector('section[data-cms-item-anchor="' + _href[1] + '"]');
                    if (el) {
                        let anchorStatus = el.getAttribute('data-anchor-status');
                        if (anchorStatus === "collapse") {
                            el.setAttribute('data-anchor-status', 'expand');
                            el.classList.add("expand");
                        }
                        else {
                            el.setAttribute('data-anchor-status', 'collapse');
                            el.classList.remove("expand");
                        }
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        }
    }

    if (itemName === 'text') {
        return (
            <div css={style.html__wrapper}>
                <div css={style.html__container} className={`check_other_panel_${checkOtherPanel} ${textVertical} ${textHorizontal}`} style={{ backgroundColor: textBgColor }}>
                    <div data-cms-html="true"
                        css={style.html__item}
                        data-cms-item-id={cmsOption.isCmsEdit && (idFirstUse === true ? itemId : itemId + "_" + index)}
                        data-cms-item-id2={cmsOption.isCmsEdit && itemId}
                        className={`cms_item htmlPanel html new__multicolumn__panel__html item__${index} ${'width__' + textWidth}`}
                        onClick={(e) => handleClick(e)}>
                        {renderHtml(content, cmsOption)}
                    </div>
                </div>
            </div>
        )
    }
    else if (itemName === 'banner') {
        return (
            <div className='cms_item bannerPanel bannerWrapperSize--standard'>
                <Slider {...settings}>
                    {
                        _imgs.map((item, index) => {
                            return <ImgItem key={index} item={item} linkUrl={linkUrl} />
                        })
                    }
                </Slider>
            </div>
        )
    }

    return (
        <div>No Item</div>
    )
}

function ImgItem({ item, linkUrl }) {
    
    if (linkUrl && linkUrl !== '') {
        return (
            <SiteLink to={linkUrl}>
                <div className='cms_item banner-item bannerSize' style={{ backgroundImage: utils.css.bgUrlStyle(item) }}></div>
            </SiteLink>
        )
    }

    return (
        <div className='cms_item banner-item bannerSize' style={{ backgroundImage: utils.css.bgUrlStyle(item) }}></div>
    )
}

//"https://jaba.com.au/sites/newjaba/media/banners/ocean1_269430438.jpg"