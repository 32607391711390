//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__bsyc', item.cssClass || item.anchorName || '');

  return (
    <section className='section contentPanel' css={style.section}>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        css={style.pageTiles}>
        {pageTileElts}
      </div>
    </section>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme;
  //const index = props.idx;

  return (

    <div css={style.pageTile__tile} className="pageTile__bsyc">
      <SiteLink className="pageTile__bsyc__content" css={style.tile__content} to={disableLink ? '' : page.pageUrl}>
        <div css={style.tile__flip__wrapper}>

          <div className="pageTile__bsyc__bg" css={style.tile__bg}>
            <div className="pageTile__bsyc__bg2" css={style.tile__bg2} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          </div>

          <div css={style.tile__details} className={`pageTile__bsyc__content__details ${colorTheme}`}>
            <div className="pageTile__bsyc__container">

              {
                (page.pageTitle && page.pageTitle !== '') &&
                <div className="pageTile__bsyc__title">
                  {page.pageTitle}
                </div>
              }

              <div className="pageTile__bsyc__desc">
                {page.tileDesc || page.description}
              </div>

              <div className="pageTile__bsyc__view">
                VIEW MORE
              </div>

            </div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}