//import { css } from '@emotion/core'
//import { mq, variables } from 'cssInJs'

export default{
  container : {
    position:'absolute',
    width: '100%',
    height: '85%',
    top:'50%',
    transform:'translateY(-50%)'
  },
  html__item : {
    position: 'absolute',
    '&.top' : {

    },
    '&.middle' : {
      top:'50%',
      transform:'translateY(-50%)',
    },
    '&.bottom' : {
      
    },
    '&.left' : {
      left: '5%',
    },
    '&.centre' : {
      left: '50%',
      transform:'translateX(-50%)'
    },
    '&.right' : {
      
    },
    '&.middle.centre' : {
      transform: 'translate(-50%, -50%)'
    }
  }
}